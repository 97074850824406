import React from 'react';
import VideoPlayer from './VideoPlayer';  // Adjust import path as needed

const VideoInfo = ({ name, videoUrl }) => (
  <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '300px' }}>
    <h3 style={{ marginBottom: '10px' }}>Video: {name}</h3>
    <VideoPlayer
      url={videoUrl}
      width="100%"
      height="200px"
    />
  </div>
);

export default VideoInfo;
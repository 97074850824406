// // PurchasePage.jsx
// import React, { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import { useAuth } from './AuthContext';
// import './PurchasePage.css'; // Create this CSS file for styling

// const stripePromise = loadStripe('pk_test_51KUGSVG0QNwLjqA6Dtkq6F38ASWrfGwt3ArwzF6f60Gn2DLsWR3nPmfqaYXiGLlSU7W4A8IJ4OoJrzlJMUMgNwRJ00KAa6sdYl');

// const CheckoutForm = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [success, setSuccess] = useState(false);
//   const { user } = useAuth();
//   const functions = getFunctions();
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setLoading(true);
//     setError(null);

//     if (!stripe || !elements) {
//       return;
//     }

//     try {
//       const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
//       const { data } = await createPaymentIntent();

//       const cardElement = elements.getElement(CardElement);

//       const { paymentIntent, error } = await stripe.confirmCardPayment(data.clientSecret, {
//         payment_method: {
//           card: cardElement,
//           billing_details: {
//             name: user.displayName || user.email,
//           },
//         },
//       });

//       if (error) {
//         throw new Error(error.message);
//       }

//       if (paymentIntent.status === 'succeeded') {
//         const confirmPayment = httpsCallable(functions, 'confirmPayment');
//         await confirmPayment({ paymentIntentId: paymentIntent.id });
//         setSuccess(true);
//       }
//     } catch (error) {
//       console.error('Payment failed:', error);
//       setError(error.message);
//     }
//     setLoading(false);
//   };

//   if (success) {
//     return (
//       <div className="success-message">
//         <h3>Payment Successful!</h3>
//         <p>You now have access to all lessons. Enjoy learning!</p>
//       </div>
//     );
//   }

//   return (
//     <form onSubmit={handleSubmit} className="checkout-form">
//       <div className="form-row">
//         <label htmlFor="card-element">Credit or debit card</label>
//         <CardElement id="card-element" className="card-element" />
//       </div>
//       {error && <div className="error-message">{error}</div>}
//       <button type="submit" disabled={!stripe || loading} className="submit-button">
//         {loading ? 'Processing...' : 'Purchase Access ($10)'}
//       </button>
//       <div className="additional-info">
//         <p>What you'll get:</p>
//         <ul>
//           <li>Access to all premium lessons</li>
//           <li>Downloadable resources</li>
//           <li>24/7 support</li>
//         </ul>
//       </div>
//     </form>
//   );
// };

// function PurchasePage() {
//   return (
//     <div className="purchase-page">
//       <h2>Purchase Premium Access</h2>
//       <Elements stripe={stripePromise}>
//         <CheckoutForm />
//       </Elements>
//     </div>
//   );
// }

// export default PurchasePage;


import React from 'react';
import './PurchasePage.css'; // Ensure this CSS file exists for styling

function PurchasePage() {
  return (
    <div className="purchase-page">
      <h2>Premium Access Coming Soon</h2>
      <div className="custom-message">
        <p>
          I have not enabled this feature yet because I want to learn more about your needs first. 
          Your feedback will help me customize the learning path to better suit your requirements.
        </p>
        <p>
          If you're interested in premium access or have specific needs, please reach out to me at{" "}
          <a href="mailto:contactus@21ifm.com">contactus@21ifm.com</a>.
        </p>
      </div>
      <div className="additional-info">
        <p>What you'll get with premium access:</p>
        <ul>
          <li>Access to all premium lessons</li>
          <li>Downloadable resources</li>
          <li>24/7 support</li>
        </ul>
      </div>
    </div>
  );
}

export default PurchasePage;
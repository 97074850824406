import React from 'react';
import './RenderSections.css';

const ScrollableNav = ({ sections }) => {
  return (
    <div className="scrollable-nav-container">
      <div className="main-content">
        {sections.map(({ id, component: Component, useCardStyle, isVideo }, index) => (
          <div
            key={id}
            id={id}
            className={`
              section 
              ${useCardStyle !== false ? 'card-style' : ''}
              ${index % 2 === 0 ? 'even-section' : 'odd-section'}
              ${isVideo ? 'video-section' : ''}
            `}
          >
            <Component />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollableNav;
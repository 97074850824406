import React from 'react';
import { Link } from 'react-router-dom';
import './FreeContent.css';
import Image from './components/Image';

const freeLessons = [
  {
    id: 1,
    title: "Rate of Change in Baseball",
    description: "Learn rate of change through a baseball example where two runners move at different velocities on a 90-foot diamond field.",
    details: {
      duration: "20 minutes",
      difficulty: "Beginner"
    },
    imageUrl: "https://storage.googleapis.com/chapters-ifm/explainers/2025/baseball_diamond.png",
    tags: ["Calculus", "Real-world Application", "Interactive"]
  }
];

function FreeContent() {
  return (
    <div className="free-content-container">
      <h1>Free Example</h1>

      {/* NEW EXPLANATION PARAGRAPH */}
      <p className="intro-text">
      We start with real-world problems, cutting through theory. Our interactive, audio and video supported lessons
      use practical examples to help you master concepts, build problem-solving skills, and succeed in exams.
      </p>

      <div className="lesson-grid">
        {freeLessons.map((lesson) => (
          <div key={lesson.id} className="lesson-card">
            <div className="card-media">
              {lesson.imageUrl && <Image src={lesson.imageUrl} alt={lesson.title} />}
            </div>
            <div className="card-content">
              <h2>{lesson.title}</h2>
              <p className="description">{lesson.description}</p>
              <div className="lesson-details">
                <span className="duration">{lesson.details.duration}</span>
                <span className="difficulty">{lesson.details.difficulty}</span>
              </div>
              <div className="tags">
                {lesson.tags.map((tag) => (
                  <span key={tag} className="tag">{tag}</span>
                ))}
              </div>
              <Link to={`/free-lesson/${lesson.id}`} className="start-button">
                Begin Interactive Lesson
              </Link>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default FreeContent;

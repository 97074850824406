import React, { useState, useEffect } from 'react';
import { sendSignInLinkToEmail, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { useAuth } from './AuthContext';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import styles from './MagicLinkSignIn.module.css'; // Create this CSS module file

function MagicLinkSignIn() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const db = getFirestore();

    useEffect(() => {
        if (user) {
            navigate('/'); // Redirect to home if user is already logged in
        }
    }, [user, navigate]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSendLink = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccess(false);

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address');
            setIsLoading(false);
            return;
        }

        try {
            // First, try to create a new user
            const userCredential = await createUserWithEmailAndPassword(auth, email, Math.random().toString(36).slice(-8));

            // If successful, set up the user in Firestore
            await setDoc(doc(db, 'users', email), {
                uid: userCredential.user.uid,
                hasPurchased: false,
                createdAt: new Date()
            });

            // Proceed with sending magic link
            const actionCodeSettings = {
                url: `${window.location.origin}/verify`,
                handleCodeInApp: true
            };

            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            localStorage.setItem('emailForSignIn', email);
            setIsLoading(false);
            setSuccess(true);
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                // User already exists, just send the magic link
                try {
                    const actionCodeSettings = {
                        url: `${window.location.origin}/verify`,
                        handleCodeInApp: true
                    };
                    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                    localStorage.setItem('emailForSignIn', email);
                    setIsLoading(false);
                    setSuccess(true);
                } catch (signInError) {
                    setIsLoading(false);
                    setError(signInError.message);
                }
            } else {
                setIsLoading(false);
                setError(error.message);
            }
        }
    };

    const handleGoogleSignIn = async () => {
        setIsLoading(true);
        setError(null);

        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Set up the user in Firestore if not already present
            await setDoc(doc(db, 'users', user.email), {
                uid: user.uid,
                hasPurchased: false,
                createdAt: new Date()
            }, { merge: true });

            setIsLoading(false);
            navigate('/');
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Sign In</h2>

            {/* Google Sign-In Section */}
            <section className={styles.section}>
                <h3 className={styles.sectionTitle}>Sign in with Google</h3>
                <p className={styles.explanation}>
                    Sign in quickly and securely with your Google account.
                </p>
                <button onClick={handleGoogleSignIn} disabled={isLoading} className={styles.googleButton}>
                    {isLoading ? 'Processing...' : 'Sign in with Google'}
                </button>
            </section>

            {/* Separator */}
            <div className={styles.separator}>
                <span>OR</span>
            </div>

            {/* Magic Link Section */}
            <section className={styles.section}>
                <h3 className={styles.sectionTitle}>Sign in with a Magic Link</h3>
                <p className={styles.explanation}>
                    Sign in securely without a password! We'll send a magic link to your email.
                    Just click the link to log in instantly. No passwords to remember or reset.
                </p>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>Magic link sent to your email! Please check your inbox.</p>}
                <form onSubmit={handleSendLink} className={styles.form}>
                    <label htmlFor="email" className={styles.label}>Email Address:</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        className={styles.input}
                    />
                    <button type="submit" disabled={isLoading} className={styles.button}>
                        {isLoading ? 'Processing...' : 'Send Magic Link'}
                    </button>
                </form>
            </section>
        </div>
    );
}

export default MagicLinkSignIn;
import React, { createContext } from 'react'; 
import SectionHeader from './SectionHeader';
import TextBlock from './TextBlock';
import Image from './Image.jsx';  // Import with .jsx extension

import VideoPlayer from './VideoPlayer.jsx';  // Import with .jsx extension
import RenderSections from './RenderSections.js';
import ConversationBlock from './ConversationBlock.js';
import ToggleContent from './ToggleContent.js';

// Custom heading and paragraph components
export const H1 = ({ children }) => (
  <h1 style={{ color: 'blue', fontSize: '2em' }}>{children}</h1>
);

export const H2 = ({ children }) => (
  <h2 style={{ color: 'green', fontSize: '1.5em' }}>{children}</h2>
);

export const P = ({ children }) => (
  <p style={{ lineHeight: '1.6' }}>{children}</p>
);

// Create a context to hold the components
const GlobalComponentsContext = createContext();

// Custom hook for easy access to the context
export const useGlobalComponents = () => React.useContext(GlobalComponentsContext);

// Provider component that wraps your app and makes the components available globally
export const GlobalComponentsProvider = ({ children }) => {
  return (
    <GlobalComponentsContext.Provider value={{ 
      SectionHeader, 
      TextBlock, 
      Image, 
      H1, 
      H2, 
      P,
      VideoPlayer,
      RenderSections,
      ConversationBlock,
      ToggleContent,
    }}>
      {children}
    </GlobalComponentsContext.Provider>
  );
};

// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC4U9-KYCJsa0kXm1lWTXThx7cKcQah5eI",
    authDomain: "options-2024.firebaseapp.com",
    projectId: "options-2024",
    storageBucket: "options-2024.appspot.com",
    messagingSenderId: "52993695082",
    appId: "1:52993695082:web:6def73cc707df9bc051913",
    measurementId: "G-6KG500EJQ8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };


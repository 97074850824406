import React from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

const VideoPlayer = ({ url, width = "100%", height = "100%" }) => {
  return (
    <div className="video-player" style={{ width }}>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height={height}
      />
    </div>
  );
}

export default VideoPlayer;
import React, { useState } from 'react';
import './ToggleContent.css';

const ToggleContent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="toggle-content-container">
      <button 
        onClick={handleToggle}
        className="toggle-button"
      >
        {isVisible ? 'Hide Details' : 'See Details'}
      </button>
      {isVisible && <div className="toggle-content">{children}</div>}
    </div>
  );
};

export default ToggleContent;

// src/components/Image.js
import React from 'react';

const Image = ({ src, alt, caption }) => (
  <div style={{ textAlign: 'center', margin: '1rem 0' }}>
    <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
    {caption && <div style={{ fontSize: '0.8rem', color: '#555' }}>{caption}</div>}
  </div>
);

export default Image;

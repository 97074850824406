// src/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2025 Interactive Mathematics. All rights reserved.</p>
      <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      {' | '}
      <a href="/terms-of-service.html" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>
    </footer>
  );
}

export default Footer;

import React from 'react';
import './ConversationBlock.css';

const ConversationBlock = ({ speaker, avatarUrl, title, children, position = 'left' }) => {
  const isRightAligned = position === 'right';
  
  return (
    <div className={`conversation-block ${isRightAligned ? 'conversation-block-right' : 'conversation-block-left'}`}>
      <img src={avatarUrl} alt={`${speaker} avatar`} className="conversation-block-avatar" />
      <div className="conversation-block-content">
        {/* <h2 className="conversation-block-title">{title}</h2> */}
        <div className={`conversation-block-text ${isRightAligned ? 'text-right' : 'text-left'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ConversationBlock;
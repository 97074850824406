// VerifyEmail.js
import React, { useState, useEffect } from 'react';
import { isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import './VerifyEmail.css';

function VerifyEmail() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setSuccess(true);
        setIsLoading(false);
        localStorage.setItem('userAuthenticated', 'true');
        setTimeout(() => navigate('/'), 3000);
      } else if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            localStorage.removeItem('emailForSignIn');
            localStorage.setItem('userAuthenticated', 'true');
            setIsLoading(false);
            setSuccess(true);
            setTimeout(() => navigate('/'), 3000);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error.message);
          });
      } else {
        setIsLoading(false);
        setError('Invalid email link or user not authenticated.');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className="verify-email-container">
      <h2 className="verify-email-title">Email Verification</h2>
      {isLoading && (
        <p className="verify-email-message verify-email-loading">Verifying your email...</p>
      )}
      {error && (
        <p className="verify-email-message verify-email-error">Error: {error}</p>
      )}
      {success && (
        <>
          <p className="verify-email-message verify-email-success">Authentication Successful!</p>
          <p className="verify-email-message">You have been successfully authenticated.</p>
          <p className="verify-email-message">Redirecting to the home page in 3 seconds...</p>
        </>
      )}
    </div>
  );
}

export default VerifyEmail;
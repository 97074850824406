// src/PrivateRoute.js

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const [hasPurchased, setHasPurchased] = useState(false);
  const [checkingPurchase, setCheckingPurchase] = useState(true);

  useEffect(() => {
    const checkPurchase = async () => {
      if (user && user.uid) {
        try {
          const db = getFirestore();
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            setHasPurchased(userDoc.data().hasPurchased || false);
          } else {
            console.log("No user document found for UID:", user.uid);
            setHasPurchased(false);
          }
        } catch (error) {
          console.error("Error checking purchase status:", error);
          setHasPurchased(false);
        }
      } else {
        setHasPurchased(false);
      }
      setCheckingPurchase(false);
    };
  
    checkPurchase();
  }, [user]);

  if (loading || checkingPurchase) return <div>Loading...</div>;
  
  if (!user) return <Navigate to="/" />;
  
  if (!hasPurchased) return <Navigate to="/purchase" />;

  return children;
};

export default PrivateRoute;
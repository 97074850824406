import React, { useState } from 'react';

const ImageInfo = ({ name, imageUrl, description }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleImageClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '100%' }}>
      {/* <h3 style={{ marginBottom: '10px' }}>Image: {name}</h3> */}
      <img
        src={imageUrl}
        alt={name}
        onClick={handleImageClick}
        style={{
          width: '100%',
          height: 'auto',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
        }}
      />
      <p>{description}</p>

      {isFullscreen && (
        <div
          onClick={handleImageClick}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <img
            src={imageUrl}
            alt={name}
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              cursor: 'pointer',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageInfo;
